<template>
  <div class="constrain part downloads-accordion">
    <div class="row-12">
      <div class="md:offset-3 md:col-9">
        <div class="row-12">
          <div class="sm:col-6 md:offset-6 search-column relative">
            <input
              v-model="searchInput"
              type="text"
              placeholder="Suchen"
            >
            <Search class="search-icon" />
          </div>
        </div>
      </div>
      <div class="md:offset-3 md:col-9 border-t-2 border-lightblue pt-12">
        <div
          v-for="thema in themen"
          :key="thema.id"
          ref="subject"
          class="subject bg-lightgray"
          :class="{filteredOut: thema.dateien.filter(datei => datei.datei.length > 0
            && datei.datei[0].name.split('.')[0].toLowerCase().includes(searchInput)).length === 0}"
        >
          <div
            v-if="thema.dateien.filter(datei => datei.datei.length > 0 &&
              datei.datei[0].name.split('.')[0].toLowerCase().includes(searchInput)).length > 0"
            class="row-12 p-4 sm:p-6"
          >
            <div class="sm:col-8 mb-4 sm:mb-0">
              <h3 v-html="thema.titel" />
            </div>
            <div class="col-6 sm:col-2 flex sm:justify-end">
              <p class="text-navy">
                {{ thema.dateien.filter(datei => datei.datei.length > 0 && datei.datei[0].name.split('.')[0]
                  .toLowerCase().includes(searchInput)).length }} {{ payload.dateien }}
              </p>
            </div>
            <div class="col-6 sm:col-2 flex justify-end items-center">
              <Chevron />
            </div>
          </div>
          <div class="bg-white files-container">
            <div
              v-for="datei in thema.dateien.filter(datei => datei.datei.length > 0 && datei.datei[0].name.split('.')[0]
                .toLowerCase().includes(searchInput))"
              :key="datei.id"
              class="single-file row-12 p-6"
            >
              <div class="md:col-9 flex items-center mb-2 sm:mb-0">
                <File />
                <a
                  v-if="datei.datei[0]"
                  class="text-navy ml-4"
                  :href="datei.datei[0].link"
                  target="_blank"
                >
                  {{ datei.datei[0].name.split('.')[0] }}
                </a>
              </div>
              <div class="md:col-3">
                <p class="text-navy">
                  {{ datei.datum }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Chevron from '../../assets/images/chevron.svg';
import File from '../../assets/images/file.svg';
import Search from '../../assets/images/search.svg';

export default {
  components: {
    Chevron,
    File,
    Search,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    themen() {
      return this.payload.themen;
    },
    subjectList() {
      return document.querySelectorAll('.subject');
    },
    // searchTerm() {
    //   return new RegExp(this.searchInput, 'gi');
    // },
  },
  watch: {
    searchInput() {
      // console.log(this.searchInput);
    },
  },
  mounted() {
    // console.log(this.themen);
    this.subjectList.forEach((subject) => {
      subject.addEventListener('click', () => {
        subject.classList.toggle('open');
        subject.lastChild.classList.toggle('expanded');
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.search-column {
  padding-bottom: 3rem;
  @screen md {
    padding-bottom: 0;
    margin-top: -8.5rem;
  }
}

input {
  position: relative;
  font-weight: 700;
  color: var(--color-navy);
  padding: px(10) px(55) px(10) px(25);
  width: 100%;
  border: 2px solid var(--color-blue);
  border-radius: px(100);
  outline: none;
  &::placeholder {
    text-transform: uppercase;
    color: var(--color-navy);
  }
}

.search-icon {
  z-index: 10;
  background-color: var(--color-white);
  position: absolute;
  top: px(27);
  right: px(20);
  transform: translateY(-50%);
  color: var(--color-navy);
  height: px(20);
  width: px(20);
}

.subject {
  margin-bottom: 2rem;
  &.filteredOut {
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    width: px(20);
    height: px(30);
    @screen sm {
      width: px(25);
    }
    @screen lg {
      width: px(30);
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.files-container {
  height: 0;
  overflow: hidden;
  &.expanded {
    height: 100%;
  }
}

.single-file {
  border-top: 2px solid var(--color-light-blue);
  border-left: 2px solid var(--color-light-blue);
  border-right: 2px solid var(--color-light-blue);
  &:last-child {
    border-bottom: 2px solid var(--color-light-blue);
  }
  &:hover {
    cursor: auto;
  }
  a {
    background-image: linear-gradient(var(--color-navy), var(--color-navy));
    background-size: 0 px(2);
    background-repeat: no-repeat;
    background-position: bottom left;
    will-change: background-size;
    transition: background-size .25s ease-out;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-size: 100% px(2);
        color: var(--color-navy);
      }
    }
  }
}
</style>
